body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  /* Dark */
  /* background-color: #1e1e1e;
  color: #fafafa; */
}

* {
  box-sizing: border-box;
}
